<template>
  <div class="mt-auto">
    <v-container>
      <div class="d-flex align-center justify-center header-brands">
        <img
          loading="lazy"
          height="160px"
          src="/img/grupo-fasipe.png"
          alt="Grupo Fasipe Educacional"
          class="d-block brand-group"
        />

        <img
          loading="lazy"
          height="73px"
          src="/img/separator.png"
          class="mx-5 mx-lg-16 separator"
        />

        <img
          loading="lazy"
          height="148px"
          src="/img/fasipepos.png"
          class="d-block age"
        />
      </div>

      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="8"
          lg="4"
          class="mt-10 grey lighten-4 pa-5 pt-4"
        >
          <v-form>
            <div
              class="
                title
                grey--text
                text--darken-1 text-center
                font-weight-light
                mb-3
              "
            >
              ESCOLHA SUA UNIDADE
            </div>

            <v-select
              background-color="white"
              outlined
              hide-details
              v-model="goTo"
              :items="items"
              label="ESCOLHA SUA UNIDADE"
            ></v-select>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-4 mt-md-6 mt-lg-12" fluid>
      <v-row justify="center">
        <template v-for="(item, i) in faculties">
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-4" :key="i">
            <Brands :item="item" />
          </v-col>

          <v-responsive
            v-if="(xl && i === 4) || (lg && i === 3)"
            :key="`width-${i}`"
            width="100%"
          ></v-responsive>
        </template>
      </v-row>
    </v-container>

    <div
      class="
        text-center
        mt-10 mt-md-10 mt-lg-16
        text-center text-uppercase
        grey--text
        text text-body-1 text-sm-h5 text-md-h4 text-lg-h3
        font-weight-thin
      "
    >
      www.fasipeposgraduacao.com.br
    </div>
  </div>
</template>

<script>
const _faculties = [
  {
    title: "SINOP - UNIFASIPE",
    img: "/img/units/faculties/unifasipe.png",
    imgWidth: "300px",
    link: "https://sinop.fasipeposgraduacao.com.br",
  },
  {
    title: "CUIABÁ - FASIPE CPA",
    img: "/img/units/faculties/fasipe-cuiaba.png",
    imgWidth: "250px",
    link: "https://pos.fasipecuiaba.com.br",
  },
  {
    title: "SORRISO - FASIPE SORRISO",
    img: "/img/units/faculties/fasipe-sorriso.png",
    imgWidth: "250px",
    link: "https://sorriso.fasipeposgraduacao.com.br",
  },
  {
    title: "DISTRITO FEDERAL - FASIPE DF",
    img: "/img/units/faculties/fasipedf.png",
    imgWidth: "250px",
    link: "https://fasipedf.fasipeposgraduacao.com.br",
  },
];

import Brands from "../cards/Brands.vue";

export default {
  data: () => ({
    goTo: "",
    faculties: _faculties,
  }),
  computed: {
    lg() {
      return this.$vuetify.breakpoint.lgOnly;
    },
    xl() {
      return this.$vuetify.breakpoint.xlOnly;
    },
    items() {
      return [
        ...this.faculties.map(({ title, link }, index) => ({
          value: link ? link : index,
          text: title,
          disabled: !link ? true : false,
        })),
      ];
    },
  },
  watch: {
    goTo() {
      location = this.goTo;
    },
  },
  components: {
    Brands,
  },
};
</script>

<style scoped>
@media (max-width: 960px) {
  .header-brands .brand-group {
    height: 112px;
  }

  .header-brands .separator {
    height: 51px;
  }

  .header-brands .age {
    height: 152px;
  }
}

@media (max-width: 600px) {
  .header-brands .brand-group {
    height: 80px;
  }

  .header-brands .separator {
    height: 36.5px;
  }

  .header-brands .age {
    height: 108.5px;
  }
}

.text {
  letter-spacing: 10px !important;
}

@media screen and (max-width: 600px) {
  .text {
    letter-spacing: 5px !important;
  }
}
</style>
